.recipePreviewContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 760px;
  height: 220px;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 2px;
  border: 1px solid var(--color-border);
}

.recipeImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 240px;
  height: 100%;
  margin-right: 13px;
}

.recipeImage {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.recipeContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.recipeTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  font-size: 22px;
  padding: 15px;
}

.recipeActions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-shrink: 0;
}

.addToRecipeBookButton {
  background-color: var(--color-secondary);
}

.deleteFromRecipeBookButton {
  background-color: var(--color-warning);
}

.buttonDark {
  background-color: transparent;
}

.recipeDetailsLink {
  text-decoration: none;
  color: black;
}