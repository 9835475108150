.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backdrop {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 100;
  width: 530px;
  height: 250px;
  padding: 30px;
  font-size: 16px;
  border: 1px solid var(--color-border);
  border-radius: 3px;
  background-color: var(--color-app-main);
}

.modalTitle {
  flex-grow: 1;
  font-size: 20px;
  padding: 0 15px;
  margin-top: 10px;
}

.modalActions {
  display: flex;
  justify-content: space-around;
  flex-shrink: 0;
  height: 50px;
  padding: 10px 0;
}

.noButton {
  background-color: var(--color-secondary);
}

.yesButton {
  background-color: var(--color-warning);
}

.buttonDark {
  background-color: transparent;
}

.modalDark {
  background-color: var(--color-app-main-2);
}