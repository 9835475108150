.searchFilterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  height: 35px;
  padding: 0 133px;
  border: 1px solid var(--color-border);
  border-radius: 2px;
}

.searchFilterItem {
  margin: 0 15px;
}

.searchFilterItem label {
  cursor: pointer;
}

.searchFilterInput {
  margin-right: 10px;
  cursor: pointer;
}