.noMatch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error {
  font-size: 60px;
  margin-bottom: 30px;
}

.message {
  font-size: 40px;
}