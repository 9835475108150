.header {
  display: flex;
  align-items: center;
  width: 100vw;
  padding: 15px;
  background-color: var(--color-primary);
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  margin-right: 50px;
}

.logo {
  width: 100%;
  height: 100%;
}

.navigation {
  display: flex;
  align-items: center;
  flex-grow: 1;
  list-style: none;
}

.navigationLink {
  margin: 0 10px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  color: black;
  text-decoration: none;
}

.activeLink {
  font-size: 22px;
  text-decoration: underline;
  pointer-events: none;
}

.headerActions {
  display: flex;
  align-items: center;
}

.themeSwitchButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  margin-right: 20px;
  cursor: pointer;
}

.themeIcon {
  width: 100%;
  height: 100%;
}

.searchIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 35px;
  height: 35px;
  margin-right: 20px;
  cursor: pointer;
}