.recipePage {
  display: flex;
  flex-direction: column;
  padding: 30px 60px;
}

.recipeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 30px 0;
}

.recipeShortDescription {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;
}

.shortDescriptionItem {
  display: flex;
  align-items: center;
  margin: 0 30px;
  font-weight: 700;
}

.servingsIcon, 
.cookingTimeIcon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.whiteIcon {
  filter: invert(1);
}

.MyRecipeBookActions {
  margin: 0 30px;
}

.nutritionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.nutrients {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 400px;
  font-weight: 700;
}

.nutrient {
  margin: 0, 10px;
  font-size: 18px;
}

.recipeImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 560px;
  height: 360px;
  border: 1px solid var(--color-border);
  border-radius: 3px;
}

.recipeImage {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.recipeFullDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recipeSummary {
  padding: 60px 0 30px 0;
  max-width: 760px;
  font-size: 18px;
  text-align: justify;
  line-height: 30px;
}

.recipeSummary a {
  pointer-events: none;
  text-decoration: none;
  color: inherit;
}

.recipeIngredientsList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  justify-items: left;
  gap: 0 60px;
  list-style: circle;
  padding-left: 0;
  font-size: 18px;
}

.recipeInstructionsTitle {
  margin: 40px 0 20px 0;
}

.recipeInstructions {
  display: flex;
  flex-direction: column;
  max-width: 760px;
}

.stepNumber {
  margin: 7px 0;
}

.step {
  margin-bottom: 30px;
  font-size: 18px;
}

.linkToSearchResults {
  margin-top: 110px;
  text-decoration: none;
}