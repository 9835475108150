@import url(https://fonts.googleapis.com/css2?family=Courgette&family=Indie+Flower&display=swap);
:root {
  --font-default: 'Courgette', serif;
  --color-primary: rgb(180, 221, 145);
  --color-secondary: rgba(245, 255, 239, 0.884);
  --color-app-background: rgb(243, 243, 243);
  --color-app-main: #fff;
  --color-border: rgb(230, 230, 230);
  --color-warning: rgb(255, 246, 246);
  --color-app-background-2: rgb(83, 83, 83);
  --color-app-main-2: rgb(48, 48, 48);
  --color-secondary-2: rgba(82, 95, 74, 0.884);
  --color-warning-2: rgb(97, 84, 84);
}

body {
  margin: 0;
  font-family: 'Courgette', serif;
  font-family: var(--font-default);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: 'Courgette', serif;
  font-family: var(--font-default);
}

.App_App__16ZpL {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
  background-color: var(--color-app-background);
}

.App_main__3ZkGI {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  width: 80vw;
  height: 100vh;
  overflow-y: auto;
  background-color: var(--color-app-main);
}

.App_AppDark__xr22L {
  background-color: var(--color-app-background-2);
}

.App_AppDark__xr22L * {
  color: var(--color-app-main);
}

.App_mainDark__2yqvJ {
  background-color: var(--color-app-main-2);
}
.HeaderComponent_header__2oZGn {
  display: flex;
  align-items: center;
  width: 100vw;
  padding: 15px;
  background-color: var(--color-primary);
}

.HeaderComponent_logoContainer__3Ip_b {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  margin-right: 50px;
}

.HeaderComponent_logo__2J-1D {
  width: 100%;
  height: 100%;
}

.HeaderComponent_navigation__420cT {
  display: flex;
  align-items: center;
  flex-grow: 1;
  list-style: none;
}

.HeaderComponent_navigationLink__27tzL {
  margin: 0 10px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  color: black;
  text-decoration: none;
}

.HeaderComponent_activeLink__Ls11C {
  font-size: 22px;
  text-decoration: underline;
  pointer-events: none;
}

.HeaderComponent_headerActions__3Shdn {
  display: flex;
  align-items: center;
}

.HeaderComponent_themeSwitchButton__1w8xe {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  margin-right: 20px;
  cursor: pointer;
}

.HeaderComponent_themeIcon__3Vc9l {
  width: 100%;
  height: 100%;
}

.HeaderComponent_searchIconContainer__qya7r {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 35px;
  height: 35px;
  margin-right: 20px;
  cursor: pointer;
}
.LoaderComponent_loaderWrapper__hm-Xx {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.LoaderComponent_ldsSpinner__3Fwvm {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.LoaderComponent_ldsSpinner__3Fwvm div {
  transform-origin: 40px 40px;
  animation: LoaderComponent_ldsSpinner__3Fwvm 1.2s linear infinite;
}
.LoaderComponent_ldsSpinner__3Fwvm div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: var(--color-primary);
}
.LoaderComponent_ldsSpinner__3Fwvm div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.LoaderComponent_ldsSpinner__3Fwvm div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.LoaderComponent_ldsSpinner__3Fwvm div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.LoaderComponent_ldsSpinner__3Fwvm div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.LoaderComponent_ldsSpinner__3Fwvm div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.LoaderComponent_ldsSpinner__3Fwvm div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.LoaderComponent_ldsSpinner__3Fwvm div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.LoaderComponent_ldsSpinner__3Fwvm div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.LoaderComponent_ldsSpinner__3Fwvm div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.LoaderComponent_ldsSpinner__3Fwvm div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.LoaderComponent_ldsSpinner__3Fwvm div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.LoaderComponent_ldsSpinner__3Fwvm div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes LoaderComponent_ldsSpinner__3Fwvm {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.SearchBarComponent_searchBarComponent__O19t7 {
  position: -webkit-sticky;
  position: sticky;
  top: -40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  background-color: var(--color-app-main);
}

.SearchBarComponent_searchBarComponentDark__38XFv {
  background-color: var(--color-app-main-2);
}

.SearchBarComponent_searchBarContainer__1eXc- {
  display: flex;
  justify-content: center;
  max-width: 1000px;
  height: 50px;
  margin: 30px 0 10px 0;
}

.SearchBarComponent_searchInputContainer__AWleD {
  position: relative;
  display: flex;
  flex-direction: row;
}

.SearchBarComponent_searchInput__3_ZhO {
  width: 585px;
  padding: 0 10px;
  border: 1px solid var(--color-border);
  border-radius: 2px;
  background-color: transparent;
}

.SearchBarComponent_searchFilterButton__2FWGp {
  position: absolute;
  right: 10px;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 22px;
  border: none;
  background-color: transparent;
  transform: translateY(-11px);
  cursor: pointer;
}

.SearchBarComponent_filterIcon__2ukts {
  width: 100%;
  height: 100%;
}

.SearchBarComponent_searchBarButton__3np-p {
  width: 80px;
  margin-left: 10px;
  cursor: pointer;
  background-color: var(--color-primary);
  border: 1px solid var(--color-border);
  border-radius: 2px;
  transition-duration: 0.3s;
}

.SearchBarComponent_searchBarButton__3np-p:hover {
  font-size: 14.4px;
}

.SearchBarComponent_searchFilterButtonClicked__3WzvO {
  opacity: 0.6;
}
.SearchFilterComponent_searchFilterContainer__1dFjD {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  height: 35px;
  padding: 0 133px;
  border: 1px solid var(--color-border);
  border-radius: 2px;
}

.SearchFilterComponent_searchFilterItem__2CpUX {
  margin: 0 15px;
}

.SearchFilterComponent_searchFilterItem__2CpUX label {
  cursor: pointer;
}

.SearchFilterComponent_searchFilterInput__197ae {
  margin-right: 10px;
  cursor: pointer;
}
.MainPage_mainPageConatiner__2yHGo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MainPage_mainPageTitle__2ej0P {
  margin-top: 40px;
  flex-shrink: 0;
}

.MainPage_mainPageActions__1kGjr {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-shrink: 0;
}
.RecipePreviewComponent_recipePreviewContainer__14LnH {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 760px;
  height: 220px;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 2px;
  border: 1px solid var(--color-border);
}

.RecipePreviewComponent_recipeImageContainer__1G03l {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 240px;
  height: 100%;
  margin-right: 13px;
}

.RecipePreviewComponent_recipeImage__1Dg6F {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.RecipePreviewComponent_recipeContent__3OgZM {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.RecipePreviewComponent_recipeTitle__1x3b2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  font-size: 22px;
  padding: 15px;
}

.RecipePreviewComponent_recipeActions__m1Sr7 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-shrink: 0;
}

.RecipePreviewComponent_addToRecipeBookButton__3Pb06 {
  background-color: var(--color-secondary);
}

.RecipePreviewComponent_deleteFromRecipeBookButton__lqOGl {
  background-color: var(--color-warning);
}

.RecipePreviewComponent_buttonDark__3fQ6_ {
  background-color: transparent;
}

.RecipePreviewComponent_recipeDetailsLink__1y8Nj {
  text-decoration: none;
  color: black;
}
.ConfirmDeleteRecipeModalComponent_overlay__xhhGy {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ConfirmDeleteRecipeModalComponent_backdrop__38O5C {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.ConfirmDeleteRecipeModalComponent_modal__2BX4R {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 100;
  width: 530px;
  height: 250px;
  padding: 30px;
  font-size: 16px;
  border: 1px solid var(--color-border);
  border-radius: 3px;
  background-color: var(--color-app-main);
}

.ConfirmDeleteRecipeModalComponent_modalTitle__mpuDT {
  flex-grow: 1;
  font-size: 20px;
  padding: 0 15px;
  margin-top: 10px;
}

.ConfirmDeleteRecipeModalComponent_modalActions__3SVc0 {
  display: flex;
  justify-content: space-around;
  flex-shrink: 0;
  height: 50px;
  padding: 10px 0;
}

.ConfirmDeleteRecipeModalComponent_noButton__2pjBE {
  background-color: var(--color-secondary);
}

.ConfirmDeleteRecipeModalComponent_yesButton__3hLlR {
  background-color: var(--color-warning);
}

.ConfirmDeleteRecipeModalComponent_buttonDark__1R5rK {
  background-color: transparent;
}

.ConfirmDeleteRecipeModalComponent_modalDark__39Ksg {
  background-color: var(--color-app-main-2);
}
.UniversalButtonComponent_button__3oBG2 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  height: 30px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition-duration: 0.3s;
}

.UniversalButtonComponent_small__tZRmU {
  width: 170px;
}

.UniversalButtonComponent_medium__3nIte {
  width: 200px;
}

.UniversalButtonComponent_large__34Y_a {
  width: 230px;
}

.UniversalButtonComponent_regular__krH5t {
  font-weight: 400;
  font-size: 16px;
  border-bottom: 1px solid black;
}

.UniversalButtonComponent_regular__krH5t:hover {
  font-size: 16.4px;
}

.UniversalButtonComponent_bold__3Y_aO {
  font-weight: 700;
  font-size: 18px;
  border-bottom: 2px solid black;
}

.UniversalButtonComponent_bold__3Y_aO:hover {
  font-size: 18.4px;
}

.UniversalButtonComponent_buttonDark__tlaeU {
  border-color: var(--color-app-main);
  background-color: transparent;
}
.RecipesContainerComponent_recipesContainer__32Xdg {
  display: flex;
  flex-direction: column;
}

.RecipesContainerComponent_totalResultsTitle__3_upt {
  margin: 0;
}

.RecipesContainerComponent_recipesList__1pt1f {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  list-style: none;
  padding: 0;
}
.NoMatchPage_noMatch__Wc_um {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NoMatchPage_error__2LOxb {
  font-size: 60px;
  margin-bottom: 30px;
}

.NoMatchPage_message__KzU84 {
  font-size: 40px;
}
.MyRecipeBookPage_favouritesPageContainer__3YaQv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MyRecipeBookPage_favouritesPageTitle__GQjYB {
  flex-shrink: 0;
}

.MyRecipeBookPage_favouritesList__2jUyR {
  flex-grow: 1;
  list-style: none;
  padding: 0;
}

.MyRecipeBookPage_favouritesPageActions__15UjA {
  display: flex;
  flex-shrink: 0;
}
.RecipePage_recipePage__3GNwN {
  display: flex;
  flex-direction: column;
  padding: 30px 60px;
}

.RecipePage_recipeContainer__2UIfQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 30px 0;
}

.RecipePage_recipeShortDescription__2f6ZH {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;
}

.RecipePage_shortDescriptionItem__2t0TH {
  display: flex;
  align-items: center;
  margin: 0 30px;
  font-weight: 700;
}

.RecipePage_servingsIcon__1SIyC, 
.RecipePage_cookingTimeIcon__KLzsq {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.RecipePage_whiteIcon__1Ter0 {
  filter: invert(1);
}

.RecipePage_MyRecipeBookActions__3Vefz {
  margin: 0 30px;
}

.RecipePage_nutritionContainer__-Lq7U {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.RecipePage_nutrients__YgX7e {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 400px;
  font-weight: 700;
}

.RecipePage_nutrient__3mdgd {
  margin: 0, 10px;
  font-size: 18px;
}

.RecipePage_recipeImageContainer__1tzHr {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 560px;
  height: 360px;
  border: 1px solid var(--color-border);
  border-radius: 3px;
}

.RecipePage_recipeImage__2IslY {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.RecipePage_recipeFullDescription__3ZkuQ {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RecipePage_recipeSummary__2YqoQ {
  padding: 60px 0 30px 0;
  max-width: 760px;
  font-size: 18px;
  text-align: justify;
  line-height: 30px;
}

.RecipePage_recipeSummary__2YqoQ a {
  pointer-events: none;
  text-decoration: none;
  color: inherit;
}

.RecipePage_recipeIngredientsList__29Itc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  justify-items: left;
  grid-gap: 0 60px;
  gap: 0 60px;
  list-style: circle;
  padding-left: 0;
  font-size: 18px;
}

.RecipePage_recipeInstructionsTitle__39npw {
  margin: 40px 0 20px 0;
}

.RecipePage_recipeInstructions__3wboJ {
  display: flex;
  flex-direction: column;
  max-width: 760px;
}

.RecipePage_stepNumber__1diL3 {
  margin: 7px 0;
}

.RecipePage_step__3iUue {
  margin-bottom: 30px;
  font-size: 18px;
}

.RecipePage_linkToSearchResults__3fAZi {
  margin-top: 110px;
  text-decoration: none;
}
