.mainPageConatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainPageTitle {
  margin-top: 40px;
  flex-shrink: 0;
}

.mainPageActions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-shrink: 0;
}