.searchBarComponent {
  position: sticky;
  top: -40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  background-color: var(--color-app-main);
}

.searchBarComponentDark {
  background-color: var(--color-app-main-2);
}

.searchBarContainer {
  display: flex;
  justify-content: center;
  max-width: 1000px;
  height: 50px;
  margin: 30px 0 10px 0;
}

.searchInputContainer {
  position: relative;
  display: flex;
  flex-direction: row;
}

.searchInput {
  width: 585px;
  padding: 0 10px;
  border: 1px solid var(--color-border);
  border-radius: 2px;
  background-color: transparent;
}

.searchFilterButton {
  position: absolute;
  right: 10px;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 22px;
  border: none;
  background-color: transparent;
  transform: translateY(-11px);
  cursor: pointer;
}

.filterIcon {
  width: 100%;
  height: 100%;
}

.searchBarButton {
  width: 80px;
  margin-left: 10px;
  cursor: pointer;
  background-color: var(--color-primary);
  border: 1px solid var(--color-border);
  border-radius: 2px;
  transition-duration: 0.3s;
}

.searchBarButton:hover {
  font-size: 14.4px;
}

.searchFilterButtonClicked {
  opacity: 0.6;
}